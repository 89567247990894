export const publicPages = [
  '/v2/reset-password/*',
  '/v2/login',
  '/v2/new-members-application',
  '/v2/new-members-application/thank-you',
  '/v2/new-members-application/merci',
  '/v2/new-members-application/gracias',
  '/v2/new-members-application/error',
  '/login/',
  'reset-password/*',
  '/new-members-application/',
  '/new-members-application/thank-you/',
  '/new-members-application/merci/',
  '/new-members-application/gracias/',
  '/new-members-application/error/',
  '/login',
  '/reset-password',
  '/new-members-application',
  '/new-members-application/thank-you',
  '/new-members-application/merci',
  '/new-members-application/gracias',
  '/new-members-application/error',
  '/convention/convention-travel-request',
]
